@import url(https://use.typekit.net/lpr1krm.css);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-style: normal;
}

html,
button {
  font-size: 18px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

iframe.payslip {
  height: 100%;
  width: 100%;
}

